<!-- 题库管理首页 -->
<template>
  <div>
    <div class="main">
      <div style="font-size: 26px; font-weight: bold;display:flex"><div style="color:#C8C8C8;cursor: pointer;" @click='goback'>&lt;题库管理</div><div>题目列表</div></div>
      <div>
        <div>
          <el-button
            type="primary"
            style="height: 34px; line-height: 10px; margin-right: 45px"
            class="cinput"
            @click='add'
            >+添加题目</el-button
          >
          <el-radio-group v-model="radio" style="height: 34px" class="cinput">
            <el-radio-button :label="item.id" v-for='(item,index) in qslist' :key='index'>{{item.name}}</el-radio-button>
            <!-- <el-radio-button label="判断题"></el-radio-button>
            <el-radio-button label="填空题"></el-radio-button>
            <el-radio-button label="主观题"></el-radio-button>
            <el-radio-button label="看图说话"></el-radio-button> -->
          </el-radio-group>
        </div>
        <div>
           <!-- <el-select
            v-model="brandId"
            placeholder="品牌"
            clearable 
            class="cinput ac"
            style="margin: 0 23px"
          >
            <el-option
              v-for="item in brandList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="value"
            placeholder="等级筛选"
            clearable 
            class="cinput ac"
            style="margin: 0 23px"
          >
            <el-option
              v-for="item in levelLists"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
          <div style="width: 221px">
            <el-input
              v-model="input"
              placeholder="请输入搜索内容"
              class="cinput ac"
              @keyup.enter.native="list"
            ></el-input>
            <div
              style="
                display: flex;
                height: 34px;
                width: 34px;
                align-items: center;
                background: #f7f7f7;
              "
            >
              <img
                v-if="isshow"
                src="../../images/search2.png"
                alt=""
                @click="list"
                style="background: #f7f7f7"
              />
              <img
                v-if="isshow == false"
                src="../../images/search1.png"
                alt=""
                style="background: #f7f7f7"
              />
            </div>
          </div>
        </div>
      </div>

      <el-table
        :header-cell-style="{ background: '#F7F7F7' }"
        :data="tableData"
        style="width: 100%; min-height: 600px"
         @selection-change="handleSelectionChange"
      >
      <el-table-column
      type="selection"
      align='center'
      width="55"/>
        <el-table-column type="index" width="50"> </el-table-column>
        <!-- <el-table-column prop="levelName" label="等级" align="center" width="220">
        </el-table-column> -->
        <el-table-column prop="content" align="left" label="题目" :show-overflow-tooltip="true" >
        </el-table-column>
        <el-table-column prop="content" align="left" label="品牌" :show-overflow-tooltip="true" >
         <template slot-scope="scope">
           <div>{{scope.row.brandId==1?'嘉顿肯妮':scope.row.brandId==2?'瑞妮朵拉':scope.row.brandId==3?'素颜+':scope.row.brandId==4?'外部学员':''}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center" width="240">
          <template slot-scope="scope">
            <div style="display: flex; justify-content: center">
              <el-link type="primary" :underline="false" @click='details(scope.row)'>查看详情</el-link>

              <el-link type="primary" :underline="false" @click='dele(scope.row)' style="margin-left:31px">删除</el-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
        "
      >
        <!-- <el-button type="primary" @click='deleall' :disabled='isdisabled'>删除选中</el-button> -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageIndex"
          :page-sizes="[10, 15, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {questiontypeList,qsList,levelList,qssaveOrUpdate,qssavedel} from "../../apis/index";
export default {
  data() {
    return {
      brandList:[
        
      ],
       brandId:'',
      pageIndex: 1,
      pageSize: 10,
      total:0,
      qslist:[
      {
          name:'选择题',
          id:1
        },
        {
          name:'判断题',
          id:2
        }
      ],
      levelLists:[],
      value: "",
      isshow: false,
      isshow2: true,
      isdisabled:true,
      currentPage: 1,
      radio: "1",
      input: "",
      tableData: [
       
      ],
    };
  },
  watch: {
    input(newdata, olddata) {
     if(newdata==''){
        this.isshow=false
       this.input=''
       this.list()
     }else{
       this.isshow=true
     }
    },
    radio(newdata, olddata) {
     this.list()
    },
    value(){
      this.list()
    },
    brandId(){
      this.list()
    },
  },
  created(){
    this.brandList=this.$store.state.brandList
    this.courseId=this.$route.query.id
    this.brandId=this.$route.query.brandId
    // this.questype()
    this.list()
    // this.leave()
  },
  //   beforeRouteLeave(to, from, next) {
  //   if(to.path=='/exercisesAdd'){//添加页，就不缓存
  //     this.$route.meta.keepAlive=false
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }else{//详情页缓存
  //    this.$route.meta.keepAlive=true
  //     setTimeout(() => {
  //       next()
  //     }, 100);
  //   }
  //   // 导航离开该组件的对应路由时调用
  //   // 可以访问组件实例 `this`
  // },
  methods: {
    goback(){
      this.$router.go(-1)
    },
   async leave() {
      //获取等级列表
      const { data } = await levelList({roleId:3});
      if (data.code == 200) {
        this.levelLists = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
   async list(){
      const params={
        questionTypeId:this.radio,
        searchStr:this.input,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        // levelId:this.value,
        brandId:this.brandId,
        courseId:this.courseId
      }
     const {data}=await qsList(params)
     if(data.code==200){
       this.tableData=data.data.records
       this.total=Number(data.data.total)
     }
    },
     async questype() {//题目类型列表
      const { data } = await questiontypeList();
      let qslist=data.data
      this.qslist = qslist;
    
    },
    add(){//添加题目
      this.$router.push({path:'/exercisesAdd',query:{courseId:this.courseId}})
    },
    details(row){//查看详情
      this.$router.push({path:'/exercisesAdd2',query:{row:JSON.stringify(row)}})
    },
    search() {
      console.log("我是搜索");
    },
     handleSelectionChange(val) {//选中
        console.log(val)
        if(val.length>0){
          this.isdisabled=false
        }else{
          this.isdisabled=true
        }
      },
    handleSizeChange(val) {
      this.pageSize=val
      console.log(`每页 ${val} 条`);
      this.list()
    },
    handleCurrentChange(val) {
      this.pageIndex=val
      console.log(`当前页: ${val}`);
      this.list()
    },
     dele(row) {
        const params={
         id:row.id,
         courseId:this.courseId
        //  isValidity:0
       }
        this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           qssavedel(params).then(res=>{
            if(res.data.code==200){
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.list()
            }else{
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
       deleall() {
        this.$confirm('此操作将永久删除选中项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      }
  },
};
</script>

<style lang='less' scoped>
.main {
  // min-height: 800px;
  background: #fff;
  margin: 19px 0;
  padding: 23px;
  border-radius: 14px;
}
.main > div:first-child {
  font-size: 26px;
  // font-weight: bold;
}
.main > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  height: 34px;
  margin: 18px 0;
  div {
    display: flex;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
/deep/.el-radio-button__inner {
  height: 34px;
  line-height: 10px;
}
.cinput /deep/.el-input__inner {
  height: 34px;
  line-height: 10px;
}
/deep/.el-button--primary {
  height: 34px; line-height: 10px; 
}
/deep/.el-table::before {
  height: 0;
}
.ac /deep/.el-input__inner {
  border: none;
  background: #f7f7f7;
  // margin-right: 11px;
}
.red {
  color: #ff1b0b;
}
</style>
